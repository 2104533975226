import { TrackingService } from 'service/tracking/trackingService';
import { NextLink } from 'components/NextLink';

export interface IAnchorNavigation {
  navigationTitle?: string | null;
  id: string;
}
export const AnchorNavigation = ({
  anchorNavigations,
}: {
  anchorNavigations: IAnchorNavigation[];
}) => {
  const links = anchorNavigations?.map((nav) => ({
    id: nav.id,
    url: nav.navigationTitle?.split(' ').join('-') ?? '',
    displayName: nav.navigationTitle ?? '',
  }));
  const onClickHandler = (target: string) => {
    TrackingService.anchorNavigation(target);
  };

  const linkList = links?.map((link, index) => (
    <NextLink
      className="inline-block first:mx-0 mmmd:first:mx-5 my-0 mx-5 text-[#292b29] hover:underline hover:underline-offset-[3px] hover:outline-none focus:underline focus:underline-offset-[3px] focus:outline-none"
      href={`#${link?.url ?? ''}`}
      key={link?.id || index.toString()}
      onClick={() => onClickHandler(link?.displayName || '')}
    >
      {link?.displayName || ''}
    </NextLink>
  ));

  return (
    <div className="sticky sm:top-14 md:top-20 bg-white z-22 w-full min-h-[65px] px-0 py-5">
      <div data-test="slider-wrapper" className="sm:hidden md:block">
        {linkList}
      </div>
      <div
        data-test="nav-wrapper-mobile"
        className="sm:block md:hidden overflow-x-auto whitespace-nowrap pb-2"
      >
        {linkList}
      </div>
    </div>
  );
};

export default AnchorNavigation;
