
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/recipes",
      function () {
        return require("private-next-pages/recipes/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/recipes"])
      });
    }
  